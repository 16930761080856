import { defineStore } from 'pinia';
import { getDatabase, ref, onValue, set, serverTimestamp, get } from 'firebase/database';

export const useNetworkLatencyStore = defineStore('networkLatency', {
    state: () => ({
        averageLatency: 0,
        measurements: [],
        timeOffset: 0,
        isInitialized: false,
    }),

    actions: {
        setupLatencyMeasurement() {
            if (this.isInitialized) {
                console.log('Latency measurement already initialized');
                return;
            }

            const db = getDatabase();
            const latencyRef = ref(db, 'latency');
            const timeOffsetRef = ref(db, '.info/serverTimeOffset');

            onValue(timeOffsetRef, (snapshot) => {
                this.timeOffset = snapshot.val() || 0;
            });

            this.measureLatency(latencyRef, 10);
        },

        measureLatency(latencyRef, remainingMeasurements) {
            if (remainingMeasurements <= 0) {
                this.isInitialized = true;
                console.log('Latency measurement initialized');
                return;
            }

            const start = performance.now();
            fetch('https://timeapi.io/api/Time/current/zone?timeZone=UTC')
                .then(response => response.json())
                .then(data => {
                    const end = performance.now();
                    const serverTime = new Date(data.dateTime).getTime();
                    const latency = (end - start) / 2;
                    this.addMeasurement(latency);
                    this.timeOffset = serverTime - (Date.now() - latency);
                    this.measureLatency(latencyRef, remainingMeasurements - 1);
                })
                .catch(error => {
                    console.error('Error measuring latency:', error);
                    this.measureLatency(latencyRef, remainingMeasurements - 1);
                });
        },

        addMeasurement(latency) {
            this.measurements.push(latency);
            if (this.measurements.length > 5) {
                this.measurements.shift();
            }
            this.averageLatency = this.measurements.reduce((sum, val) => sum + val, 0) / this.measurements.length;
        },

        getCurrentServerTime() {
            return Date.now() + this.timeOffset;
        },

        async reportLatency(roomId, userId) {
            const db = getDatabase();
            const latencyRef = ref(db, `rooms/${roomId}/latencies/${userId}`);
            await set(latencyRef, {
                latency: this.averageLatency,
                timestamp: serverTimestamp()
            });
        },

        async getMaxLatency(roomId) {
            const db = getDatabase();
            const latenciesRef = ref(db, `rooms/${roomId}/latencies`);
            const snapshot = await get(latenciesRef);
            let maxLatency = 0;

            if (snapshot.exists()) {
                const latencies = snapshot.val();
                Object.values(latencies).forEach(data => {
                    if (data.latency > maxLatency) {
                        maxLatency = data.latency;
                    }
                });
            }

            return maxLatency;
        },

        async remeasureLatency(measurementCount = 5) {
            this.measurements = [];
            const latencyRef = ref(getDatabase(), 'latency');
            await this.measureLatency(latencyRef, measurementCount);
            console.log('Latency re-measured:', this.averageLatency);
            return this.averageLatency;
        }
    },
});