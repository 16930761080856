//router/index.js
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Landing',
        component: () => import('@/screens/LandingScreen.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/screens/LoginScreen.vue')
    },
    {
        path: '/find-id',
        name: 'FindID',
        component: () => import('@/screens/FindIDScreen.vue')
    },
    {
        path: '/score-sample',
        name: 'ScoreSample',
        component: () => import('@/screens/ScoreSample.vue')
    },
    {
        path: '/room',
        name: 'Room',
        component: () => import('@/screens/RoomScreen.vue')
    },
    {
        path: '/main/:roomId',
        name: 'Main',
        component: () => import('@/screens/MainScreen.vue'),
        props: true
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('@/screens/SignupScreen.vue')
    },
    {
        path: '/setacc',
        name: 'setacc',
        component: () => import('@/screens/SettingAccount.vue')
    },
    {
        path: '/temple',
        name: 'temple',
        component: () => import('@/screens/MohapTemple.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router