import { defineStore } from 'pinia'

export const useScoreInfoStore = defineStore('scoreInfo', {
    state: () => ({
        metadata: {
            title: '',
            composer: ''
        },
        musicalInfo: {
            timeSignature: {
                beats: 4,
                beatType: 4
            },
            keySignature: {
                fifths: 0,
                mode: 'major'
            },
            tempo: {
                bpm: 120
            }
        },
        layout: {
            pageLayout: [],
            songForm: []
        },
        specialMeasures: {
            beatChanges: [],
            bpmChanges: [],
            fermatas: []
        },
        parts: [],
        partGroups: [],
        totalMeasures: 0,
    }),
    actions: {
        parseXML(xmlString) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "text/xml");

            this.parseMetadata(xmlDoc);
            this.parseMusicalInfo(xmlDoc);
            this.parseLayout(xmlDoc);
            this.parseSpecialMeasures(xmlDoc);
            this.parsePartGroups(xmlDoc);
            this.parseParts(xmlDoc);
        },
        parseMetadata(xmlDoc) {
            const metadata = xmlDoc.querySelector('metadata');
            if (metadata) {
                this.metadata = {
                    title: metadata.querySelector('title')?.textContent || '',
                    composer: metadata.querySelector('composer')?.textContent || '',
                };
            }
        },
        parseMusicalInfo(xmlDoc) {
            const musicalInfo = xmlDoc.querySelector('musical-info');
            if (musicalInfo) {
                this.musicalInfo = {
                    timeSignature: {
                        beats: parseInt(musicalInfo.querySelector('time-signature beats')?.textContent) || 4,
                        beatType: parseInt(musicalInfo.querySelector('time-signature beat-type')?.textContent) || 4,
                    },
                    keySignature: {
                        fifths: parseInt(musicalInfo.querySelector('key-signature fifths')?.textContent) || 0,
                        mode: musicalInfo.querySelector('key-signature mode')?.textContent || 'major',
                    },
                    tempo: {
                        bpm: parseInt(musicalInfo.querySelector('tempo bpm')?.textContent) || 120,
                    },
                };
            }
        },
        parseLayout(xmlDoc) {
            const layout = xmlDoc.querySelector('layout');
            if (layout) {
                // 페이지 레이아웃 파싱
                const pageLayout = layout.querySelector('page-layout');
                if (pageLayout) {
                    const pages = Array.from(pageLayout.querySelectorAll('page'));
                    this.layout.pageLayout = pages.map(page => ({
                        number: parseInt(page.getAttribute('number')),
                        measureRange: {
                            start: parseInt(page.querySelector('measure-range')?.getAttribute('start')) || 0,
                            end: parseInt(page.querySelector('measure-range')?.getAttribute('end')) || 0,
                        },
                    }));

                    // Calculate total measures
                    if (pages.length > 0) {
                        const lastPage = pages[pages.length - 1];
                        const lastMeasureRange = lastPage.querySelector('measure-range');
                        if (lastMeasureRange) {
                            this.totalMeasures = parseInt(lastMeasureRange.getAttribute('end')) || 0;
                        }
                    }
                }

                // 노래 형식 파싱
                const songForm = layout.querySelector('song-form');
                if (songForm) {
                    this.layout.songForm = Array.from(songForm.querySelectorAll('form')).map(form => ({
                        number: parseInt(form.getAttribute('number')),
                        name: form.querySelector('name')?.textContent || '',
                        formRange: {
                            start: parseInt(form.querySelector('form-range')?.getAttribute('start')) || 0,
                            end: parseInt(form.querySelector('form-range')?.getAttribute('end')) || 0,
                        },
                    }));
                }
            }
        },
        parseSpecialMeasures(xmlDoc) {
            const specialMeasures = xmlDoc.querySelector('special-measures');
            if (specialMeasures) {
                this.specialMeasures = {
                    beatChanges: this.parseBeatChanges(specialMeasures),
                    bpmChanges: this.parseBpmChanges(specialMeasures),
                    fermatas: this.parseFermatas(specialMeasures),
                };
            } else {
                // <special-measures> 요소가 없을 경우 빈 배열로 초기화
                this.specialMeasures = {
                    beatChanges: [],
                    bpmChanges: [],
                    fermatas: []
                };
            }
        },
        parseBeatChanges(specialMeasures) {
            return Array.from(specialMeasures.querySelectorAll('beat-change marking')).map(marking => ({
                measureAfter: parseInt(marking.querySelector('measure-after')?.textContent) || 0,
                beats: parseInt(marking.querySelector('beats')?.textContent) || 4,
                beatType: parseInt(marking.querySelector('beat-type')?.textContent) || 4,
            }));
        },
        parseBpmChanges(specialMeasures) {
            return Array.from(specialMeasures.querySelectorAll('bpm-change marking')).map(marking => ({
                measureAfter: parseInt(marking.querySelector('measure-after')?.textContent) || 0,
                type: marking.querySelector('type')?.textContent || 'nan',
                bpmEnd: parseInt(marking.querySelector('bpm-end')?.textContent) || 120,
            }));
        },
        parseFermatas(specialMeasures) {
            return Array.from(specialMeasures.querySelectorAll('fermata marking')).map(marking => ({
                measure: parseInt(marking.querySelector('measure')?.textContent) || 0,
                beforeBeat: parseFloat(marking.querySelector('before-beat')?.textContent) || 0,
                durationBeat: parseFloat(marking.querySelector('duration-beat')?.textContent) || 2,
                afterBeat: parseFloat(marking.querySelector('after-beat')?.textContent) || 0,
            }));
        },
        parseParts(xmlDoc) {
            const partsElement = xmlDoc.querySelector('parts');
            if (partsElement) {
                this.parts = [];
                Array.from(partsElement.querySelectorAll('part')).forEach(part => {
                    this.parts.push({
                        id: part.getAttribute('id') || '',
                        name: part.querySelector('name')?.textContent || '',
                        channel: parseInt(part.querySelector('channel')?.textContent) || 0,
                        staves: parseInt(part.querySelector('staves')?.textContent) || 1,
                        isTab: part.querySelector('istab')?.textContent === 'true',
                    });
                });
            }
        },
        parsePartGroups(xmlDoc) {
            const partGroups = xmlDoc.querySelectorAll('part-group');
            if (partGroups) {
                this.partGroups = Array.from(partGroups).map(group => ({
                    number: parseInt(group.getAttribute('number')) || 0,
                    name: group.querySelector('name')?.textContent || '',
                    groupSymbol: group.querySelector('group-symbol')?.textContent || '',
                    groupBarline: group.querySelector('group-barline')?.textContent || '',
                    parts: Array.from(group.querySelectorAll('part')).map(part => ({
                        id: part.getAttribute('id') || '',
                        name: part.querySelector('name')?.textContent || '',
                        channel: parseInt(part.querySelector('channel')?.textContent) || 0,
                        staves: parseInt(part.querySelector('staves')?.textContent) || 1,
                        isTab: part.querySelector('istab')?.textContent === 'true',
                    })),
                }));
            }
        },
    },
    getters: {
        getTitle: (state) => state.metadata.title,
        getComposer: (state) => state.metadata.composer,
        getTimeSignature: (state) => `${state.musicalInfo.timeSignature.beats}/${state.musicalInfo.timeSignature.beatType}`,
        getTempo: (state) => state.musicalInfo.tempo.bpm,
        getKeySignature: (state) => state.musicalInfo.keySignature,
        getPages: (state) => state.layout.pageLayout,
        getSongForms: (state) => state.layout.songForm,
        getSpecialMeasures: (state) => state.specialMeasures,
        getParts: (state) => state.parts,
        getPartGroups: (state) => state.partGroups,
        getTotalMeasures: (state) => state.totalMeasures,
    },
});