// src/store/auth.js
import { defineStore } from 'pinia';
import { auth, db } from '../firebase';
import {
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile,
    sendPasswordResetEmail,
    deleteUser,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from 'firebase/auth';
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
    deleteDoc,
    Timestamp,
    updateDoc,
} from 'firebase/firestore';
import { useThemeStore } from './themeStore';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null,
        userProfile: null,
        loading: true,
        error: null,
        themes: {
            isDarkMode: true,
            scoreTheme: 'invert',
            isCollapsed: false,
        },
        scoreSettings: {
            isPageMode: true,
            scoreFilter: 'invert',
        },
    }),
    actions: {
        async login(email, password) {
            console.log('로그인 시도 중...');
            this.loading = true;
            this.error = null;
            try {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                console.log('Firebase 로그인 성공:', userCredential);
                this.user = userCredential.user;
                await this.fetchUserProfile();
                await this.updateDisplayName();
                console.log('로그인 완료. 닉네임:', this.userProfile?.nickname || '익명');
                return true;
            } catch (error) {
                console.error("로그인 오류:", error);
                this.error = error.message;
                return false;
            } finally {
                this.loading = false;
                console.log('로그인 프로세스 완료');
            }
        },
        async logout() {
            try {
                await signOut(auth);
                this.clearUserData();
            } catch (error) {
                this.error = error.message;
                console.error("로그아웃 오류:", error);
            }
        },
        clearUserData() {
            this.user = null;
            this.userProfile = null;
            this.error = null;
            this.themes = {
                isDarkMode: true,
                scoreTheme: 'invert',
                isCollapsed: false,
            };
            this.scoreSettings = {
                isPageMode: true,
                scoreFilter: 'invert',
            };
        },
        async checkAuth() {
            return new Promise((resolve) => {
                onAuthStateChanged(auth, async (user) => {
                    this.user = user;
                    if (user) {
                        await this.fetchUserProfile();
                        await this.updateDisplayName();
                    } else {
                        this.clearUserData();
                    }
                    this.loading = false;

                    const themeStore = useThemeStore();
                    if (this.isAuthenticated) {
                        themeStore.initializeThemes(this.themes);
                    } else {
                        themeStore.initializeThemes(null);
                    }

                    resolve(user);
                });
            });
        },
        async fetchUserProfile() {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.userProfile = docSnap.data();
                    if (this.userProfile.themes) {
                        this.themes = { ...this.themes, ...this.userProfile.themes };
                    }
                    if (this.userProfile.scoreSettings) {
                        this.scoreSettings = { ...this.scoreSettings, ...this.userProfile.scoreSettings };
                    }
                    console.log('사용자 프로필 가져옴:', this.userProfile);
                } else {
                    console.log("해당 문서가 존재하지 않습니다!");
                    this.userProfile = null;
                }
            } catch (error) {
                console.error("사용자 프로필 가져오기 오류:", error);
                this.userProfile = null;
            }
        },
        async updateDisplayName() {
            if (!this.user || !this.userProfile) return;
            try {
                if (this.user.displayName !== this.userProfile.nickname) {
                    await updateProfile(this.user, {
                        displayName: this.userProfile.nickname
                    });
                    console.log('DisplayName 업데이트됨:', this.userProfile.nickname);
                }
            } catch (error) {
                console.error("DisplayName 업데이트 오류:", error);
            }
        },
        async resetPassword(nickname, email) {
            try {
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('nickname', '==', nickname), where('email', '==', email));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    await sendPasswordResetEmail(auth, email);
                    return true;
                }
                return false;
            } catch (error) {
                console.error('비밀번호 재설정 오류:', error);
                throw error;
            }
        },
        async deleteAccount(password) {
            if (!this.user) return { success: false, message: "사용자가 로그인되어 있지 않습니다." };

            try {
                const credential = EmailAuthProvider.credential(
                    this.user.email,
                    password
                );
                await reauthenticateWithCredential(this.user, credential);

                const userDoc = await getDoc(doc(db, 'users', this.user.uid));
                const userData = userDoc.data();

                if (userData.timestamp && userData.timestamp instanceof Timestamp) {
                    const creationTime = userData.timestamp.toDate();
                    const now = new Date();
                    const hoursSinceCreation = (now - creationTime) / (1000 * 60 * 60);

                    if (hoursSinceCreation < 24) {
                        return { success: false, message: `계정 생성 후 24시간이 지나지 않았습니다. ${Math.ceil(24 - hoursSinceCreation)}시간 후에 다시 시도해주세요.` };
                    }
                }

                await deleteDoc(doc(db, 'users', this.user.uid));
                await deleteUser(this.user);

                this.clearUserData();
                return { success: true, message: "계정이 성공적으로 삭제되었습니다." };
            } catch (error) {
                console.error("계정 삭제 오류:", error);
                this.error = error.message;
                return { success: false, message: "계정 삭제 중 오류가 발생했습니다: " + error.message };
            }
        },
        async updateUserThemes({ isDarkMode, scoreTheme, isCollapsed }) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'themes.isDarkMode': isDarkMode,
                    'themes.scoreTheme': scoreTheme,
                    'themes.isCollapsed': isCollapsed,
                });
                this.themes = { isDarkMode, scoreTheme, isCollapsed };
                console.log('사용자 테마 업데이트됨:', this.themes);
            } catch (error) {
                console.error("사용자 테마 업데이트 오류:", error);
            }
        },
        async updateIsCollapsed(isCollapsed) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'themes.isCollapsed': isCollapsed,
                });
                this.themes.isCollapsed = isCollapsed;
                console.log('isCollapsed 업데이트됨:', isCollapsed);
            } catch (error) {
                console.error("isCollapsed 업데이트 오류:", error);
            }
        },
        async updateUserScoreSettings({ isPageMode, scoreFilter }) {
            if (!this.user) return;
            try {
                const docRef = doc(db, 'users', this.user.uid);
                await updateDoc(docRef, {
                    'scoreSettings.isPageMode': isPageMode,
                    'scoreSettings.scoreFilter': scoreFilter,
                });
                this.scoreSettings = { isPageMode, scoreFilter };
                console.log('사용자 악보 설정 업데이트됨:', this.scoreSettings);
            } catch (error) {
                console.error("사용자 악보 설정 업데이트 오류:", error);
            }
        },
    },
    getters: {
        isAuthenticated: (state) => !!state.user,
        userNickname: (state) => state.userProfile?.nickname || '익명',
        currentUser: (state) => state.user,
        userThemes: (state) => state.themes,
        userScoreSettings: (state) => state.scoreSettings,
    }
});