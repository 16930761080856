// src/store/themeStore.js
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useAuthStore } from './auth';

export const useThemeStore = defineStore('theme', () => {
    const authStore = useAuthStore();

    // 반응형 상태 정의
    const isDarkMode = ref(true);
    const scoreTheme = ref('invert');

    // 테마 초기화 함수
    const initializeThemes = (userThemes) => {
        if (userThemes) {
            // 인증된 사용자의 경우 Firestore에서 테마 설정 불러오기
            isDarkMode.value = userThemes.isDarkMode;
            scoreTheme.value = userThemes.scoreTheme;
        } else {
            // 인증되지 않은 사용자의 경우 localStorage에서 테마 설정 불러오기
            const savedIsDarkMode = localStorage.getItem('isDarkMode');
            const savedScoreTheme = localStorage.getItem('scoreTheme');

            if (savedIsDarkMode !== null) {
                isDarkMode.value = savedIsDarkMode === 'true';
            }

            if (savedScoreTheme) {
                scoreTheme.value = savedScoreTheme;
            }
        }
    };

    // 테마 전환 함수
    const toggleTheme = () => {
        isDarkMode.value = !isDarkMode.value;
    };

    // 테마 변경 감지 및 저장
    watch(
        [isDarkMode, scoreTheme],
        ([newIsDarkMode, newScoreTheme]) => {
            if (authStore.isAuthenticated) {
                // 인증된 사용자의 경우 Firestore에 테마 업데이트
                authStore.updateUserThemes(newIsDarkMode, newScoreTheme);
            } else {
                // 인증되지 않은 사용자의 경우 localStorage에 테마 저장
                localStorage.setItem('isDarkMode', newIsDarkMode);
                localStorage.setItem('scoreTheme', newScoreTheme);
            }

            // 전역 클래스 업데이트
            if (newIsDarkMode) {
                document.body.classList.remove('light-mode');
            } else {
                document.body.classList.add('light-mode');
            }
        },
        { immediate: false }
    );

    // 초기 테마 설정 적용
    watch(
        () => authStore.isAuthenticated,
        (isAuthenticated) => {
            if (isAuthenticated) {
                initializeThemes(authStore.userThemes);
            } else {
                initializeThemes(null);
            }
        },
        { immediate: true }
    );

    return {
        isDarkMode,
        scoreTheme,
        toggleTheme,
        initializeThemes,
    };
});
