import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase, ref, onValue } from "firebase/database";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDc7UlFl4gk0ZiQ3gCabgCD5cGnv8VPayE",
    authDomain: "mohap-f1c43.firebaseapp.com",
    databaseURL: "https://mohap-f1c43-default-rtdb.firebaseio.com",
    projectId: "mohap-f1c43",
    storageBucket: "mohap-f1c43.appspot.com",
    messagingSenderId: "1078294446473",
    appId: "1:1078294446473:web:4833dfa891e7eaf4066158",
    measurementId: "G-EQ52FWGYD4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const rtdb = getDatabase(app);
const storage = getStorage(app);

const connectedRef = ref(rtdb, ".info/connected");
onValue(connectedRef, (snap) => {
    if (snap.val() === true) {
        console.log("Connected to Firebase RTDB");
    } else {
        console.log("Disconnected from Firebase RTDB");
    }
});

export { auth, db, functions, rtdb, storage };