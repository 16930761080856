// metronome.js

import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { useNetworkLatencyStore } from './networkLatencyStore';
import { useScoreInfoStore } from './InfoParser';
import { useRoomStore } from './room';
import * as Tone from 'tone';

export const useMetronomeStore = defineStore('metronome', () => {
    const isPlaying = ref(false);
    const currentBeat = ref(1);
    const prebeatCount = ref(0);
    const bpm = ref(120);
    const timeSignature = ref('4/4');
    const volume = ref(0.5);
    const soundType = ref('sine');
    const audioDeviceDelay = ref(Math.max(0, parseInt(localStorage.getItem('metronomeAudioDeviceDelay') || '400')));

    // 그래픽 표시를 위한 현재 마디
    const currentMeasure = ref(1);

    // 메트로놈에 사용되는 실제 마디 수
    const measureCount = ref(1);

    const isPaused = ref(true);
    const prebeat = ref(Math.max(0, parseInt(localStorage.getItem('prebeat') || '0')));
    const lastStoppedMeasure = ref(1);
    const isFirstBeat = ref(true);

    const networkLatencyStore = useNetworkLatencyStore();
    const scoreInfoStore = useScoreInfoStore();
    const roomStore = useRoomStore();

    const totalMeasures = computed(() => {
        return typeof scoreInfoStore.getTotalMeasures === 'function'
            ? scoreInfoStore.getTotalMeasures()
            : scoreInfoStore.getTotalMeasures;
    });

    const effectiveBPM = computed(() => {
        const [, denominator] = timeSignature.value.split('/').map(Number);
        return denominator === 8 ? bpm.value * 2 : bpm.value;
    });

    let nextNoteTime = 0;
    let timerID = null;
    const scheduleAheadTime = 0.1;

    let synth;
    let stickSynth;

    const beatsPerMeasure = computed(() => {
        const [numerator] = timeSignature.value.split('/').map(Number);
        return numerator || 4;
    });

    const initializeDelays = () => {
        if (!localStorage.getItem('metronomeAudioDeviceDelay')) {
            audioDeviceDelay.value = 400;
            localStorage.setItem('metronomeAudioDeviceDelay', '400');
        }
    };

    initializeDelays();
    networkLatencyStore.setupLatencyMeasurement();

    const initializeSynths = () => {
        synth = new Tone.MembraneSynth({
            pitchDecay: 0.008,
            octaves: 2,
            oscillator: {
                type: soundType.value
            },
            envelope: {
                attack: 0.0006,
                decay: 0.5,
                sustain: 0,
                release: 0.1
            }
        }).toDestination();
        synth.volume.value = Tone.gainToDb(volume.value);

        stickSynth = new Tone.NoiseSynth({
            noise: {
                type: 'white',
                playbackRate: 3,
                volume: -5,
            },
            envelope: {
                attack: 0.001,
                decay: 0.05,
                sustain: 0,
                release: 0.05
            }
        }).toDestination();
        stickSynth.volume.value = Tone.gainToDb(volume.value);
    };

    const userBPMOverride = ref(false); // 사용자가 BPM을 수동으로 변경했는지 추적하는 플래그

    const updateBPM = (newBPM, source = 'user') => {
        bpm.value = newBPM;
        if (source === 'user') {
            userBPMOverride.value = true; // 사용자가 BPM을 변경하면 플래그 설정
        }
        if (synth) {
            synth.volume.value = Tone.gainToDb(volume.value);
            synth.oscillator.type = soundType.value; // 추가: 오실레이터 타입 업데이트
        }
        if (stickSynth) {
            stickSynth.volume.value = Tone.gainToDb(volume.value);
        }
        if (roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, { bpm: newBPM });
        }
    };

    const updateTimeSignature = (newTimeSignature) => {
        timeSignature.value = newTimeSignature;
    };

    const start = (serverStartTime = null, prebeatParam = 0, lastStoppedBeat = 1) => {
        if (isPlaying.value) return;
        isPlaying.value = true;
        isPaused.value = false;
        if (isPaused.value) {
            currentMeasure.value = Math.max(1, lastStoppedMeasure.value);
            measureCount.value = currentMeasure.value;  // Initialize measureCount
            currentBeat.value = lastStoppedBeat;
            prebeatCount.value = Math.max(0, prebeatParam * beatsPerMeasure.value);
        } else {
            currentBeat.value = 1;
            currentMeasure.value = lastStoppedMeasure.value;
            measureCount.value = currentMeasure.value;  // Initialize measureCount
            prebeatCount.value = Math.max(0, prebeat.value * beatsPerMeasure.value);
        }
        Tone.start();
        initializeSynths();

        console.log(scoreInfoStore.getSpecialMeasures.beatChanges);

        const currentTime = Tone.now();
        if (serverStartTime) {
            const serverNow = networkLatencyStore.getCurrentServerTime();
            const timeSinceStart = Math.max(0, (serverNow - serverStartTime) / 1000);
            const beatDuration = 60 / effectiveBPM.value;
            const elapsedBeats = Math.max(0, Math.floor(timeSinceStart / beatDuration));
            if (!isPaused.value) {
                currentBeat.value = ((elapsedBeats) % beatsPerMeasure.value) + 1;
                currentMeasure.value += Math.floor(elapsedBeats / beatsPerMeasure.value);
            }
            // 서버 시작 시간까지 지연
            const delayTime = Math.max(0, serverStartTime - serverNow);
            setTimeout(() => {
                nextNoteTime = Tone.now() + (beatDuration - (timeSinceStart % beatDuration));
                isFirstBeat.value = true;
                scheduler();
            }, delayTime);
        } else {
            nextNoteTime = currentTime + 0.1;
            isFirstBeat.value = true;
            scheduler();
        }
    };

    // 기존의 stop 함수에 override 초기화 추가
    const stop = () => {
        isPlaying.value = false;
        isPaused.value = true;
        prebeatCount.value = 0;
        lastStoppedMeasure.value = measureCount.value;  // Use measureCount instead of currentMeasure
        currentMeasure.value = measureCount.value;
        currentBeat.value = 1;
        clearTimeout(timerID);
        if (synth) {
            synth.dispose();
            synth = null;
        }
        if (stickSynth) {
            stickSynth.dispose();
            stickSynth = null;
        }
        isFirstBeat.value = true;
        resetOverride(); // Override 플래그 초기화
    };


    const pause = () => {
        if (!isPlaying.value) return;
        lastStoppedMeasure.value = measureCount.value;  // Use measureCount
        isPlaying.value = false;
        isPaused.value = true;
        clearTimeout(timerID);
        isFirstBeat.value = true;
    };

    const resetMetronome = () => {
        stop();
        currentBeat.value = 1;
        currentMeasure.value = 1;
        measureCount.value = 1;
        lastStoppedMeasure.value = 1;
    };

    const resume = () => {
        if (!isPaused.value) return;
        start(null, prebeat.value, currentBeat.value);
    };

    const scheduler = () => {
        if (!isPlaying.value) return;

        while (nextNoteTime < Tone.now() + scheduleAheadTime) {
            checkAndUpdateMetronome();
            if (prebeatCount.value > 0) {
                schedulePrebeat(nextNoteTime);
                prebeatCount.value--;
            } else {
                scheduleNote(nextNoteTime);
                currentBeat.value = (currentBeat.value % beatsPerMeasure.value) + 1;
                if (currentBeat.value === 1) {
                    // measureCount 증가 로직은 scheduleNote로 이동
                }
            }
            nextNoteTime += 60.0 / effectiveBPM.value;
        }
        timerID = setTimeout(scheduler, 25);
    };

    const scheduleNote = (time) => {
        const delayedTime = time + audioDeviceDelay.value / 1000;
        const note = currentBeat.value === 1 ? 'E5' : 'C5';

        if (prebeatCount.value > 0) {
            stickSynth.triggerAttackRelease('8n', delayedTime);
        } else {
            if (currentBeat.value === 1) {
                // 다음 마디 첫 음 직전에 currentMeasure 업데이트
                Tone.Draw.schedule(() => {
                    currentMeasure.value = measureCount.value;
                    console.log(`Current measure updated to ${currentMeasure.value}`);
                }, delayedTime - 0.01); // 0.01초 전에 업데이트
            }

            synth.triggerAttackRelease(note, 0.1, delayedTime);

            if (currentBeat.value === beatsPerMeasure.value) {
                // 마지막 음이 끝나는 시점에 measureCount 증가
                Tone.Draw.schedule(() => {
                    measureCount.value++;
                    console.log(`Measure count incremented to ${measureCount.value}`);
                }, delayedTime + 0.1); // 음 지속 시간(0.1초) 후에 업데이트
            }
        }
    };

    const schedulePrebeat = (time) => {
        const delayedTime = time + audioDeviceDelay.value / 1000;
        stickSynth.triggerAttackRelease('8n', delayedTime);
    };

    const updateVolume = (newVolume) => {
        volume.value = newVolume;
        if (synth) {
            synth.volume.value = Tone.gainToDb(newVolume);
        }
        if (stickSynth) {
            stickSynth.volume.value = Tone.gainToDb(newVolume);
        }
    };

    const updateSoundType = (newSoundType) => {
        soundType.value = newSoundType;
        if (synth) {
            synth.oscillator.type = newSoundType;
        }
    };

    const updateAudioDeviceDelay = (newDelay) => {
        const delay = Math.max(0, parseInt(newDelay));
        audioDeviceDelay.value = delay;
        localStorage.setItem('metronomeAudioDeviceDelay', delay.toString());
    };

    const resetCurrentBeat = () => {
        currentBeat.value = 1;
        currentMeasure.value = 0;
    };

    const updatePrebeat = (newPrebeat) => {
        prebeat.value = Math.max(0, parseInt(newPrebeat));
        console.log('Updating Prebeat:', prebeat.value);
    };

    const setCurrentBeat = (beat) => {
        if (!isPlaying.value) {
            currentBeat.value = beat;
        }
    };

    const toggleMetronome = async () => {
        if (!roomStore.isInRoom) return;


        if (isPlaying.value) {
            await pause();
            await roomStore.updateRoomSettings(roomStore.currentRoom, {
                isPlaying: false,
                lastStoppedMeasure: currentMeasure.value
            });
        } else {
            const startTime = networkLatencyStore.getCurrentServerTime() + 1000;

            await roomStore.updateRoomSettings(roomStore.currentRoom, {
                isPlaying: true,
                startTime: startTime,
                bpm: bpm.value,
                prebeat: prebeat.value,
                timeSignature: timeSignature.value,
                lastStoppedMeasure: lastStoppedMeasure.value - 1,
            });

            start(startTime, prebeat.value);
        }
    };

    const increaseCurrentMeasure = () => {
        if (isPlaying.value) {
            console.log("Cannot change measure while playing");
            return;
        }

        if (typeof totalMeasures.value === 'number' && currentMeasure.value < totalMeasures.value - 1) {
            currentMeasure.value++;
            lastStoppedMeasure.value = currentMeasure.value;
            if (roomStore.currentRoom) {
                roomStore.updateRoomSettings(roomStore.currentRoom, {
                    lastStoppedMeasure: currentMeasure.value,
                    lastStoppedBeat: currentBeat.value
                });
            }
        }
    };

    const decreaseCurrentMeasure = () => {
        if (isPlaying.value) {
            return;
        }

        if (typeof totalMeasures.value === 'number' && currentMeasure.value > 1) {
            currentMeasure.value--;
            lastStoppedMeasure.value = currentMeasure.value;
            if (roomStore.currentRoom) {
                roomStore.updateRoomSettings(roomStore.currentRoom, {
                    lastStoppedMeasure: currentMeasure.value,
                    lastStoppedBeat: currentBeat.value
                });
            }
        }
    };

    const setCurrentMeasure = (measure) => {
        if (!isPlaying.value) {
            currentMeasure.value = measure;
            measureCount.value = measure;  // Update measureCount as well
            lastStoppedMeasure.value = measure;
        }
    };

    const jumpToMeasure = (measure) => {
        if (isPlaying.value) {
            return;
        }

        if (measure < 1 || measure > totalMeasures.value) {
            return;
        }

        currentMeasure.value = measure;
        measureCount.value = measure;  // Update measureCount as well
        lastStoppedMeasure.value = measure;
        currentBeat.value = 1;

        checkAndUpdateMetronome();

        if (roomStore.currentRoom) {
            roomStore.updateRoomSettings(roomStore.currentRoom, {
                lastStoppedMeasure: measure,
                lastStoppedBeat: 1
            });
        }
    };

    watch(() => roomStore.currentRoomData?.lastStoppedMeasure, (newMeasure) => {
        if (!isPlaying.value && newMeasure !== undefined && newMeasure !== currentMeasure.value) {
            setCurrentMeasure(newMeasure);
        }
    });

    watch(() => roomStore.currentRoomData?.lastStoppedBeat, (newBeat) => {
        if (!isPlaying.value && newBeat !== undefined && newBeat !== currentBeat.value) {
            setCurrentBeat(newBeat);
        }
    });

    watch(() => roomStore.currentRoomData, (newRoomData) => {
        if (newRoomData && !isPlaying.value) {
            setCurrentMeasure(newRoomData.lastStoppedMeasure || 1);
            setCurrentBeat(newRoomData.lastStoppedBeat || 1);
        }
    }, { immediate: true });

    const checkAndUpdateMetronome = () => {
        if (!scoreInfoStore.specialMeasures || typeof scoreInfoStore.specialMeasures !== 'object') {
            return;
        }

        const bpmChanges = scoreInfoStore.specialMeasures.bpmChanges || [];
        const beatChanges = scoreInfoStore.specialMeasures.beatChanges || [];
        let newBPM = scoreInfoStore.getTempo;  // Initial BPM
        let newTimeSignature = timeSignature.value;

        // BPM 변경 사항 확인
        for (const change of bpmChanges) {
            if (change && change.measureAfter <= measureCount.value) {
                newBPM = change.bpmEnd;
            } else {
                break;  // Assuming the BPM change list is sorted
            }
        }

        // 박자 변경 사항 확인
        for (const change of beatChanges) {
            if (change && change.measureAfter + 1 <= measureCount.value) {
                newTimeSignature = `${change.beats}/${change.beatType}`;
            } else {
                break;  // Assuming the beat change list is sorted
            }
        }

        // 사용자가 BPM을 덮어쓰지 않았을 경우에만 BPM 업데이트
        if (!userBPMOverride.value && newBPM !== bpm.value) {
            const oldBPM = bpm.value;
            updateBPM(newBPM, 'score'); // 'score' 소스 표시
            console.log(`BPM changed to ${newBPM} at measure ${measureCount.value}`);

            // 새로운 BPM에 맞춰 nextNoteTime 재계산
            const elapsedTime = Tone.now() - (nextNoteTime - 60 / oldBPM);
            const remainingTime = 60 / newBPM - elapsedTime;
            nextNoteTime = Tone.now() + remainingTime;
        }

        // 박자표가 변경되었는지 확인하고 업데이트
        if (newTimeSignature !== timeSignature.value) {
            updateTimeSignature(newTimeSignature);
            console.log(`Time signature changed to ${newTimeSignature} at measure ${measureCount.value}`);
        }
    };

    // Override 플래그를 초기화하는 함수
    const resetOverride = () => {
        userBPMOverride.value = false;
    };


    return {
        isPlaying,
        currentBeat,
        prebeatCount,
        bpm,
        timeSignature,
        volume,
        soundType,
        audioDeviceDelay,
        beatsPerMeasure,
        currentMeasure,
        totalMeasures,
        isPaused,
        prebeat,
        lastStoppedMeasure,
        start,
        stop,
        pause,
        resetMetronome,
        resume,
        updateBPM, // 수정된 updateBPM 함수 노출
        updateTimeSignature,
        updateVolume,
        updateSoundType,
        updateAudioDeviceDelay,
        resetCurrentBeat,
        updatePrebeat,
        toggleMetronome,
        decreaseCurrentMeasure,
        increaseCurrentMeasure,
        jumpToMeasure,
        setCurrentMeasure,
        setCurrentBeat,
    };
});
