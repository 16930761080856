<template>
  <div
    :class="{
      'landing-page': isLandingPage,
      'light-mode': !themeStore.isDarkMode,
      'ios-device': isIOS,
    }"
  >
    <div class="size-block" :style="sizeBlockStyle">
      <div class="img-cont">
        <img :src="errorCharPng" alt="Error character" />
        <i class="mohap_logo"></i>
      </div>
      모바일 페이지를 준비중입니다.<br />좀더 큰 화면으로 이용해 주세요.
    </div>
    <div id="app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useThemeStore } from '@/store/themeStore';
import { useAuthStore } from '@/store/auth';
import { useRoomStore } from '@/store/room';
import { watch, computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import errorCharPng from '@/assets/error_char.png';
import * as Tone from 'tone';
import { debounce } from 'lodash';

export default {
  name: 'App',
  setup() {
    const { t } = useI18n();
    const themeStore = useThemeStore();
    const authStore = useAuthStore();
    const roomStore = useRoomStore();
    const router = useRouter();
    const route = useRoute();

    const isLandingPage = computed(() => route.name === 'Landing');
    const isIOS = ref(false);
    const safeAreaBottom = ref(0);

    const synth = new Tone.Synth({
      volume: -59
    }).toDestination();

    const playButtonSound = debounce(() => {
      synth.triggerAttackRelease("C4", "1n");
    }, 1);

    const handleButtonClick = (event) => {
      if (event.target.tagName === 'BUTTON') {
        playButtonSound();
      }
    };

    // 더블 탭 확대 방지를 위한 변수
    let lastTouchEnd = 0;

    const handleTouchEnd = (event) => {
      const now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    };

    const preventZoom = (event) => {
      // Pinch Zoom 방지
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    onMounted(async () => {
      isIOS.value = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isIOS.value) {
        const root = document.documentElement;
        safeAreaBottom.value = parseInt(
          getComputedStyle(root).getPropertyValue('--sat') || '0',
          10
        );
      }

      await authStore.checkAuth();
      if (authStore.isAuthenticated) {
        themeStore.initializeThemes(authStore.themes);
      }

      document.addEventListener('click', handleButtonClick);

      // 더블 탭 확대 방지를 위한 이벤트 리스너 추가
      document.addEventListener('touchend', handleTouchEnd, { passive: false });
      document.addEventListener('touchmove', preventZoom, { passive: false });
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleButtonClick);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchmove', preventZoom);
    });

    const sizeBlockStyle = computed(() => {
      if (isIOS.value) {
        return {
          height: `calc(100vh - ${safeAreaBottom.value}px)`,
        };
      }
      return {};
    });

    watch(
      () => authStore.user,
      async (newUser) => {
        if (newUser) {
          await authStore.fetchUserProfile();
          themeStore.initializeThemes(authStore.themes);
        } else {
          themeStore.initializeThemes(null);
        }
        const publicPages = ['Landing', 'Login', 'FindID', 'Signup'];
        if (!newUser && !publicPages.includes(router.currentRoute.value.name)) {
          router.push({ name: 'Login' });
        }
      }
    );

    router.beforeEach(async (to, from, next) => {
      await authStore.checkAuth();
      await roomStore.checkRoomParticipation();

      const publicPages = [
        'Landing',
        'Login',
        'FindID',
        'Signup',
        'ScoreSample',
      ];
      const authRequired = !publicPages.includes(to.name);

      if (!authStore.isAuthenticated && authRequired) {
        return next({ name: 'Login' });
      }

      if (authStore.isAuthenticated) {
        if (roomStore.isInRoom) {
          if (
            !['Main', 'CreateRoom', 'setacc', 'temple'].includes(to.name)
          ) {
            return next({
              name: 'Main',
              params: { roomId: roomStore.currentRoom },
            });
          }
        } else {
          if (
            !['Landing', 'Room', 'CreateRoom', 'setacc', 'temple'].includes(
              to.name
            )
          ) {
            return next({ name: 'Room' });
          }
        }
      }

      next();
    });

    return {
      themeStore,
      authStore,
      t,
      errorCharPng,
      isLandingPage,
      isIOS,
      sizeBlockStyle,
    };
  },
};
</script>

<style>
* {
  touch-action: manipulation;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: NanumSquare;
  border: none;
  outline: none;
  scrollbar-width: auto !important;
}

:root {
  --shadow-1: none;
  --shadow-2: none;
  --highlight-color: #cc2e0e;
  --text-color: #e7e7e7;
  --green: #00ff00;
  --red-warning: #ff0000;
  --red-button: #AE002A;
  --1-13: #151515;
  --2-13: #1e1e1e;
  --3-13: #252525;
  --4-13: #303030;
  --5-13: #424242;
  --6-13: #505050;
  --7-13: #767676;
  --1-12: #151515;
  --2-12: #1e1e1e;
  --3-12: #252525;
  --4-12: #303030;
  --5-12: #424242;
  --6-12: #505050;
  --7-12: #767676;
  --1-11: #151515;
  --2-11: #1e1e1e;
  --3-11: #252525;
  --4-11: #303030;
  --5-11: #424242;
  --6-11: #505050;
  --7-11: #767676;
  --1-10: #151515;
  --2-10: #1e1e1e;
  --3-10: #252525;
  --4-10: #303030;
  --5-10: #424242;
  --6-10: #505050;
  --7-10: #767676;
  --1-9: #151515;
  --2-9: #1e1e1e;
  --3-9: #252525;
  --4-9: #303030;
  --5-9: #424242;
  --6-9: #505050;
  --7-9: #767676;
  --1-8: #151515;
  --2-8: #1e1e1e;
  --3-8: #252525;
  --4-8: #303030;
  --5-8: #424242;
  --6-8: #505050;
  --7-8: #767676;
  --1-7: #151515;
  --2-7: #1e1e1e;
  --3-7: #252525;
  --4-7: #303030;
  --5-7: #424242;
  --6-7: #505050;
  --7-7: #767676;
  --dark: #000;
  --sat: env(safe-area-inset-bottom);
}

.light-mode {
  --shadow-1: 4px 4px 8px rgba(0, 0, 0, 0.05);
  --shadow-2: 4px 0px 8px rgba(0, 0, 0, 0.03);
  --highlight-color: #db592b;
  --text-color: #000;
  --green: #68b468;
  --red-warning: #ff0000;
  --red-button: #AE002A;
  --1-13: #f9f9f9;
  --2-13: #f9f9f9;
  --3-13: #f9f9f9;
  --4-13: #f9f9f9;
  --5-13: #f9f9f9;
  --6-13: #f9f9f9;
  --7-13: #f9f9f9;
  --1-12: #e7e7e7;
  --2-12: #e1e1e1;
  --3-12: #e1e1e1;
  --4-12: #e1e1e1;
  --5-12: #e1e1e1;
  --6-12: #e1e1e1;
  --7-12: #e1e1e1;
  --1-11: #c8c8c8;
  --2-11: #c8c8c8;
  --3-11: #c8c8c8;
  --4-11: #c8c8c8;
  --5-11: #c8c8c8;
  --6-11: #c8c8c8;
  --7-11: #c8c8c8;
  --1-10: #aaaaaa;
  --2-10: #aaaaaa;
  --3-10: #aaaaaa;
  --4-10: #aaaaaa;
  --5-10: #aaaaaa;
  --6-10: #aaaaaa;
  --7-10: #aaaaaa;
  --1-9: #999999;
  --2-9: #999999;
  --3-9: #999999;
  --4-9: #999999;
  --5-9: #999999;
  --6-9: #999999;
  --7-9: #999999;
  --1-8: #858585;
  --2-8: #858585;
  --3-8: #858585;
  --4-8: #858585;
  --5-8: #858585;
  --6-8: #858585;
  --7-8: #858585;
  --1-7: #767676;
  --2-7: #767676;
  --3-7: #767676;
  --4-7: #767676;
  --5-7: #767676;
  --6-7: #767676;
  --7-7: #767676;
  --dark: #c8c8c8;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--2-13);
  color: var(--text-color);
  -ms-overflow-style: none;
}

button {
  display: flex;
  width: 80%;
  height: 46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 23px;
  background-color: var(--4-11);
  color: var(--text-color);
  border: none;
  opacity: 0.9;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.1s ease;
}

button:hover {
  opacity: 1;
  font-weight: 400;
}

button:active {
  opacity: 0.7;
  transform: scale(0.95);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 25px;
}

h6 {
  font-size: 10px;
}

input {
  width: 80%;
  height: 45px;
  background-color: var(--4-13);
  border-radius: 25px;
  margin-bottom: 15px;
  padding: 0 20px;
  border: 1px solid var(--4-12);
  outline: none;
  transition: border-right-color 0s ease;
  color: var(--text-color);
  font-size: 14px;
}

input:focus {
  border: 1px solid var(--highlight-color);
  background-color: var(--4-12);
}

.size-block {
  display: none;
}

@media (max-width: 550px), (max-height: 550px) {
  body {
    background-color: var(--1-10);
  }

  button:hover {
    opacity: 0.9;
    font-weight: 600;
  }

  .landing-page .size-block {
    display: none !important;
  }

  .size-block {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000000;
    background-color: var(--2-13);
    justify-content: center;
    align-items: center;
    color: var(--highlight-color);
    text-align: center;
    flex-direction: column;
    line-height: 1.5;
  }

  .size-block img {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
  }

  .size-block i {
    font-size: 60px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .img-cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.ios-device .size-block {
  height: calc(100vh - var(--sat));
}

.ios-safe-area-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: env(safe-area-inset-bottom);
  background-color: var(--2-13);
  z-index: 1000;
}

.ios-device #app {
  padding-bottom: env(safe-area-inset-bottom);
}

.ios-device .bottom-nav {
  margin-bottom: env(safe-area-inset-bottom);
}
</style>