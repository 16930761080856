import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import { pinia } from './store'
import './assets/fonts.css'
import en from '@/languages/en.json'
import ko from '@/languages/ko.json'
import CustomScrollbar from 'custom-vue-scrollbar'
import 'custom-vue-scrollbar/dist/style.css'
import * as Vex from 'vexflow'

const i18n = createI18n({
    legacy: false,
    locale: 'ko',
    fallbackLocale: 'en',
    messages: { en, ko }
})

const app = createApp(App)

app.config.warnHandler = () => null
app.component(CustomScrollbar.name, CustomScrollbar)
app.use(pinia)
app.use(router)
app.use(i18n)
app.config.globalProperties.$vex = Vex

app.mount('#app')